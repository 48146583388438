import React from "react"
import {Helmet} from "react-helmet"
import academyIcon from "../images/ecosystem/fund/academy-icon.svg"
import climateIcon from "../images/ecosystem/fund/climate-icon.svg"
import grantsIcon from "../images/ecosystem/fund/grants-icon.svg"
import hackatronIcon from "../images/ecosystem/fund/hackatron-icon.svg"
import ventureIcon from "../images/ecosystem/fund/ventures-icon.svg"
import terraIcon from "../images/ecosystem/fund/terra-icon.svg"
import reliefIcon from "../images/ecosystem/fund/relief-fund-icon.svg"
import integrationIcon from "../images/ecosystem/fund/integration-icon.svg";
import tronGraphs from "../images/ecosystem/fund/tron-graphs.png"
import defineCentralization from "../images/ecosystem/fund/define-centralization.png"
import background from "../images/ecosystem/fund/background.mp4"
import aiIcon from "../images/ecosystem/fund/AI icon.svg";
import charityIcon from "../images/ecosystem/fund/charity.svg";
import grants from "../images/ecosystem/fund/grants.svg";
const TrondaoVentures = () => {

	const programs = [
        {
            "title": "HackaTRON",
            "icon": hackatronIcon,
            "description": "Empowering visionaries to shape the future of TRON.",
            "url" : "/hackatron/"
        },
        {
            "title": "TRON/BTTC Integration Fund",
            "icon": integrationIcon,
            "description": "Unlocking the full potential of BitTorrent Chain: Discover new possibilities for growth and collaboration with TRON.",
            "url" : "https://forms.gle/jqUJ1odwP2D3C1uj6"
        },
        {
            "title": "TRON DAO Ventures",
            "icon": ventureIcon,
            "description": "Empowering the future of startups: Discover, invest, optimize, and exit with our elite team.",
            "url" : "https://forms.gle/q5k6FKGJStd9zV2Q6"
        },
        // {
        //     "title": "Ambassador Grants",
        //     "icon": grantsIcon,
        //     "description": "Providing continuous support to communities, developers, and influencers of the TRON ecosystem.",
        //     "url" : "/ambassador-grants/"
        // },
		// {
        //     "title": "TRON Academy Program",
        //     "icon": academyIcon,
        //     "description": "Partnering with top-tier university blockchain organizations to mentor and equip the leading next-gen innovators.",
        //     "url" : "/academy/"
        // },
		{
            "title": "TRON Climate Initiative",
            "icon": climateIcon,
            "description": "Committing to a sustainable future: Prioritizing energy efficiency, reducing our carbon footprint, and caring for the environment.",
            "url" : "https://docs.google.com/forms/d/e/1FAIpQLScofJ3Am0vPmUPh9E0KCfFpdbEYnmf-e41FT9UV5KFJqbWm5g/viewform?usp=sf_link"
        },
		{
            "title": "Terra Developer Incentive Fund",
            "icon": terraIcon,
            "description": "Allocating $10 million in grants to aid developers and communities affected by the UST/Luna disaster.",
            "url" : "https://twitter.com/justinsuntron/status/1526149180909289472"
        },
        {
            "title": "Binance Web3 Industry Recovery Initiative",
            "icon": reliefIcon,
            "description": "Contributing $50 million to assist struggling blockchain projects through the Binance Web3 Industry Recovery Initiative.",
            "url" : "https://www.binance.com/en/blog/ecosystem/web3-leaders-join-binance-to-spearhead-web3-industry-recovery-initiative-5285759314040744618"
        },
        {
            "title": "AI Fund",
            "icon": aiIcon,
            "description": "Revolutionizing blockchain technology with AI: Unlocking unparalleled utility and possibilities for the future of Web3.",
            "url" : "https://trondao.ai/"
        },
        {
            "title": "TRON DAO Charity",
            "icon": charityIcon,
            "description": "Leading charitable initiatives and using web3 to further make a positive impact.",
            "url" : "https://hejustinsun.com/philanthropy",
            "btn": 'Learn More'
        },
        {
            "title": "Developer Grants",
            "icon": grants,
            "description": "The Developer Grant Program will facilitate a gateway and provide resources from the internal TRON DAO team towards multiple core developers to further expand the TRON ecosystem.",
            "url" : "https://docs.google.com/forms/d/e/1FAIpQLSd7-kMeuXIyuL_cXCbVz64gK4dArUzaPkeBUr2oc0uPNavwcg/viewform",
            "class": "grants-wrap",
        },
	]

	return (
    	<div className="trondao-ventures">
            <Helmet>
                <title>TRON Ecosystem Fund | TRONDAO</title>
                <meta name="description"
                        content="TRON DAO Ecosystem Fund Will Define Decentralization"/>
                <meta property="og:description" content="TRON DAO Ecosystem Fund Will Define Decentralization"/>
                <meta property="og:title" content="TRON Ecosystem Fund | TRONDAO"/>
            </Helmet>

            <div className="ecosystem-fund">
            	<div className="ecosystem-fund-header">
                    <video autoPlay muted loop playsInline className="d-block mx-auto">
                        <source src={background} type="video/mp4"/>
                    </video>
            		<div className="ecosystem-fund-header-title d-flex flex-column flex-md-row align-items-start">
                        <div className="col-11 mx-auto col-lg-8 mx-lg-0">
    				        <h1 className="text-uppercase">TRON DAO Ecosystem Fund will <span>define decentralization</span></h1>
                            <p>
                             The TRON DAO Ecosystem Fund aims at innovating Web3. It has a variety of options, including HackaTRON, TRON/BTTC Integration Fund, TRON DAO Ventures, an industry relief fund, ambassadorGrants offering, TRON Academy program, climate initiative, and more upcoming programs. All of these draw from the $1 billion funding allocated to empowering the best and brightest BUIDLing in the TRON ecosystem.
                            </p>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdZRYCkMA5LiQ1ZF6b0sHH3z7EiFbgOzDsehaDljmSwT-i9tQ/viewform" className="" id="ecosystem-fund-button-submit-your-project-top" target="_blank" rel="noopener noreferrer"><button className="ecosystem-fund-button">Submit Your Project</button></a>
                        </div>
                    </div>
            	</div>

            	<div className="ecosystem-fund-sections col-11 col-lg-9 mx-auto">

                    <div className="ecosystem-fund-define d-flex flex-column flex-lg-row align-items-center justify-content-between">
                        <div>
                            <img src={defineCentralization} width="250" height="210" alt="globe with interconnected people" className="img-fluid" />
                        </div>
                        <div className="mt-5 mt-lg-0">
                            <h1>Define Decentralization</h1>
                            <p>
                            We believe the decentralized web3 future will be collaboratively multi-chain with seamless interoperability, offering essential tools for life-giving participation in the global economy and the global community. We want to enable and empower that future, along with fun applications and enjoyable experiences, too.
                            </p>
                        </div>
                    </div>

            		<div className="ecosystem-fund-program">
            			<h2>Programs</h2>
            			<div className="ecosystem-fund-program-squares">
            				{
            					programs.map((program, i) => {
            						return (
            							<div key={i} className={`ecosystem-fund-program-square d-flex flex-column justify-content-between align-items-center ${program.class || ''}`}>
                                            <div>
                                                <div className="ecosystem-fund-program-square-img-wrapper d-flex justify-content-center align-items-center">
                                                    <img src={program.icon} alt={program.title+" icon"} className="img-fluid" />
                                                </div>
                                                <h3 className="mt-5">{program.title}</h3>
                                                <p className="mt-3">{program.description}</p>
                                            </div>
                                            <div>
                                                <a href={program.url} className="mt-4" id={"ecosystem-fund-btn-apply-" + program.title} target="_blank" rel="noopener noreferrer">{program.btn || 'Apply'}</a>
                                            </div>
	            						</div>
            						)
            					})
            				}
            			</div>
            		</div>

            		<div className="ecosystem-fund-last d-flex flex-column flex-lg-row justify-content-between">
            			<div className="order-2 order-lg-1 d-flex flex-column align-items-start align-items-md-center align-items-lg-start mt-5 mt-lg-0">
            				<h1>A Perfect Fit</h1>
            				<div className="mt-2 mt-md-4">
            					<ul>
	            					<li>Building on a trusted layer-1</li>
	            					<li>Working toward or already possessing a MVP, Testnet, or Beta</li>
	            					<li>Leveraging their own resources while stewarding outside funding</li>
	            					<li>Uniting a team of innovators and investors who are dedicated to success</li>
	            					<li>Synergizing for a multi-chain, interoperable, decentralized future</li>
	            				</ul>
	            				<p>Does this sound like you? We'd like to hear from you.</p>
            				</div>
            				<a href="https://docs.google.com/forms/d/e/1FAIpQLSdZRYCkMA5LiQ1ZF6b0sHH3z7EiFbgOzDsehaDljmSwT-i9tQ/viewform" className="mt-3 mt-md-0" id="ecosystem-fund-button-submit-your-project-lower" target="_blank" rel="noopener noreferrer"><button className="ecosystem-fund-button">Submit Your Project</button></a>
            			</div>
            			<div className="order-1 order-lg-2 d-flex align-items-center justify-content-center">
            				<img src={tronGraphs} alt="tron logo and different types of graphs" className="img-fluid" />
            			</div>
            		</div>
            	</div>
            </div>
        </div>
   	)
}

export default TrondaoVentures